import React, { useEffect, useRef, useState } from 'react';
import styles from './header.module.css';
import Logo from './logo.png';
import RedLogo from './logo_red.png';
import menuWhiteIcon from './menu_white.svg';
import menuBlackIcon from './menu_black.svg';
import closeIcon from './close.svg';
import closeWhite from './close_white.svg';
import caret from './caret.svg';
import { HashLink as Link } from 'react-router-hash-link';
import LocalizedStrings from 'react-localization';
import { registerStrings } from '../../../modules/localisation/LocalisationModule';
import { LANGUAGE_DATA } from '../../../modules/localisation/textData';
import LanguageDropdown from '../../Common/LanguageDropdown';
import back from './back.png'
import { useNavigate } from 'react-router-dom';
import { getCookie } from '../../../modules/cookie';
import { iOS, logOut } from '../../../common/utils';
import { Button } from '../Button/Button';
import buttonIcon from './rechargeButtonIcon.png';

function Header({ redLogo, customCrossAction, logoText, logoTextColor, leftIcon, hideCrossIcon, forceActive, leftAction, extraStyles }) {
  const [isMobileMenuActive, setIsMobileMenuActive] = useState(false);
  const [isLogoutMenuVisible, setIsLogoutMenuVisible] = useState(false);
  const [userData, setuserData] = useState(null)
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const [scrolled, setScrolled] = useState(false);
  let strings = new LocalizedStrings(LANGUAGE_DATA.HEADER);
  const logoutHelper = () => {
    logOut();
  }
  registerStrings(strings);
  const handleMobileMenuClick = () => {
    setIsMobileMenuActive(!isMobileMenuActive);
  };
  useEffect(() => {
    const userCookie = getCookie('user');
    if(userCookie){

      const user = JSON.parse(userCookie);
      if(user) {
        setuserData(user);
      }
      
    }
    window.addEventListener('scroll', (e) => {
      if (window.scrollY > 0) {
        setScrolled(true)
      } else {
        setScrolled(false)
      }
    });

    
  }, []);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsLogoutMenuVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <header id='header' className={`${styles.desktopHeader} ${(scrolled || forceActive) && styles.scrolledHeader} ${extraStyles}`}>
        <ul className={styles.navItemsDesktop}>
          <Link to="/#app" className={styles.logo}>
            <img src={redLogo || scrolled || forceActive ? RedLogo: Logo } alt="Logo" />
          </Link>
          {(forceActive || scrolled) && !logoText && <>
            <li className={styles.menuListItem}>
              <Link to="/#app">{strings.home}</Link>
            </li>
            <li className={styles.menuListItem}>
              <Link to="/#features-container">{strings.features}</Link>
            </li>
            <li className={styles.menuListItem}>
              <Link to="/careers">{strings.career}</Link>
            </li>
            <li className={styles.menuListItem}>
              <Link to="/safety">{strings.transparency}</Link>
            </li>
            <li className={styles.menuListItem}>
              <Link smooth={true} to="/join-us">{strings.starTrainer}</Link>
            </li>
            <li className={styles.menuListItem}>
              <Link smooth={true} to="/become-game-host">{strings.becomeGameHost}</Link>
            </li>
            <li className={styles.menuListItem}>
              <Link smooth={true} className='red-link' to="/become-frnd-celeb">{strings.becomeFrndCeleb}</Link>
            </li>
          </>}
        </ul>

        <div className={styles.rightItemsDesktop}>
         {(scrolled || forceActive) &&  <LanguageDropdown />}
         {userData ? 
            <div ref={dropdownRef} className={styles.loginItemContainer} onClick={() => {
             setIsLogoutMenuVisible(!isLogoutMenuVisible)
            }}>
              {userData && userData.avatar_url && <img src={userData.avatar_url} className={styles.avatar} />}
              <div>
                <div className={styles.loginHeading}>{userData?.name || 'Login Now'}</div>
                <div className={styles.loginSubHeading}> {userData?.mobile_no ? `Phone: +91 ${userData?.mobile_no}` : 'To recharge coins'}</div>
              </div>

              <img src={caret} className={styles.loginCaret} />
              {isLogoutMenuVisible && <div  className={styles.logoutContainer}>
                <Button onPress={logoutHelper} buttonText={'Logout'} backgroundColor={"green"} />
                </div>}
            </div>
         :
          <div onClick={() => navigate("/login")} className={styles.loginButton}>Login</div>}
        </div>
      </header>

      <header className={`${styles.mobileHeader} ${(scrolled || forceActive) && styles.scrolledHeader} ${isMobileMenuActive && styles.activeHeader} ${extraStyles}`}>
        {logoText ?
          <div onClick={leftAction} className={styles.logoTextContainer}>
            {leftIcon && <img src={back} alt='header icon' className={styles.leftIcon} />}
            <div className={styles.logoText} style={{ color: (scrolled || forceActive) ? 'black' : logoTextColor || 'white' }}>{logoText}</div>
          </div>
          : <Link className={styles.logo} to="/#app" onClick={() => setIsMobileMenuActive(false)}>
            <img src={redLogo || scrolled || isMobileMenuActive || forceActive ? RedLogo : Logo} alt="Logo" />
          </Link>}
        {customCrossAction ? <div style={{ flex: 1, justifyContent: 'flex-end', display: 'flex' }} className={`${styles.mobileMenuIcon}`} onClick={customCrossAction}>
          <img  width={18} src={scrolled || isMobileMenuActive || forceActive ? closeIcon : closeWhite} alt="Close Menu" />
        </div> :
          !hideCrossIcon && <div style={{ flex: 1, justifyContent: 'flex-end', display: 'flex', alignItems: 'center' }} className={styles.mobileMenuIcon}>
            {!iOS() && <Button shimmer={false} onPress={() => {
              const accessToken = getCookie('access_token');
              if (accessToken) {
                navigate('/packages');
              } else {
                navigate('/login')
              }
            }} className={styles.downloadAppButton  } buttonText={"RECHARGE COINS"} backgroundColor={"#4AA9FF"} leftImage={buttonIcon} />
}
            {isMobileMenuActive ? (
              <img  onClick={handleMobileMenuClick} className={styles.rightIcon} src={closeIcon} alt="Close Menu" />
            ) : (
              <img  onClick={handleMobileMenuClick} className={styles.rightIcon} src={scrolled || forceActive ? menuBlackIcon : menuWhiteIcon} alt="Open Menu" />
            )}
          </div>}

        <nav className={`${styles.mobileMenu} ${isMobileMenuActive ? styles.active : ''}`}>

          <div className={styles.navItems}>
            <ul className={styles.menuList}>
              <li className={`${styles.menuListItem} ${styles.loginItem}`}>
                <div className={styles.loginItemContainer} onClick={() => {
                  !userData && navigate("/login")
                }}>
                  {userData && userData.avatar_url && <img src={userData.avatar_url} className={styles.avatar} />}
                  <div>
                    <div className={styles.loginHeading}>{userData?.name || 'Login Now'}</div>
                    <div className={styles.loginSubHeading}> {userData?.mobile_no ? `Phone: ${userData?.mobile_no}` : 'To recharge coins'}</div>
                  </div>

                  {!userData && <img src={caret} className={styles.loginCaret} />}
                </div>
              </li>
              <li className={styles.menuListItem}>
                <Link smooth onClick={handleMobileMenuClick} to="/#app">{strings.home}</Link>
              </li>
              <li className={styles.menuListItem}>
                <Link smooth onClick={handleMobileMenuClick} to="/#features-container">{strings.features}</Link>
              </li>
              <li className={styles.menuListItem}>
                <Link smooth onClick={handleMobileMenuClick} to="/careers">{strings.career}</Link>
              </li>
              <li className={styles.menuListItem}>
                <Link smooth onClick={handleMobileMenuClick} to="/safety">{strings.transparency}</Link>
              </li>
              <li className={styles.menuListItem}>
                <Link smooth onClick={handleMobileMenuClick} to="/join-us">{strings.starTrainer}</Link>
              </li>
              <li className={styles.menuListItem}>
                <Link smooth onClick={handleMobileMenuClick} to="/become-game-host">{strings.becomeGameHost}</Link>
              </li>
              <li className={styles.menuListItem}>
                <Link smooth onClick={handleMobileMenuClick} className='red-link' to="/become-frnd-celeb">{strings.becomeFrndCeleb}</Link>
              </li>
              {userData && <li style={{ borderBottomWidth: 0}} className={styles.menuListItem}>
                <div style={{
                  color: '#7E7E7E'
                }} onClick={logoutHelper}>{strings.logout}</div>
              </li>}
            </ul>
            <div className={styles.languageDropDownContainer}>
              <LanguageDropdown />
            </div>
          </div>
        </nav>
      </header>
    </>
  );
}

export default Header;