
export const googleAppStoreTrackedLink = "https://frndapp.sng.link/D8792/cey3";
export const googleAppLink = "https://frndapp.onelink.me/Td7S/qc1ixxru";

export const InvestorLinks = {
    "IndiaQuotient" : "https://www.indiaquotient.in/companies",
    "ElevationCap": "https://elevationcapital.com/portfolio/frnd",
    "Krafton": "https://twitter.com/Anuj_Tandon/status/1472777957433294853?s=20"
}

export const OpenPositions = "https://wandering-wanderer-161.notion.site/Roles-FRND-1379b8ca466947d9a512d55dc08a0c36";

export const PrivacyPolicyLink = "https://docs.google.com/document/u/4/d/13KQcLXk5OJgDs9_kPM-68uW1uAUdUGZEwLGNo-bwImg/mobilebasic";