import './footer.css';
import frndWhite from '../../../images/frnd_white.png';
import GoogleBadge from './frndIcon.svg';
import { Button } from '../../Common/Button/Button';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

function Footer() {

  const navigate = useNavigate()
  const navigateToDownloadApk = () => {
    navigate("/download-app");
}

  const scrollElement = (section) => {
    switch(section) {
      case "top": 
        window.scrollTo({ top:0 , left: 0, behavior: 'smooth' });
        return;
      case "features":
        document.getElementsByClassName("features-container")[0].scrollIntoView({ behavior: 'smooth' });
        return;
      case "careers":
        document.getElementsByClassName("talent-container")[0].scrollIntoView({ behavior: 'smooth' });
        return;
      case "privacy_policy":
        window.open("/privacy");
        return;
    }
  }

  return (
    <div id="footer-container" className='footer-container'>
        <div>
            <div onClick={() => scrollElement("top")}><img width="100" src={frndWhite} /></div>
            <div className="nav-list">
                <div onClick={() => scrollElement("top")} className="nav-list-item">Home</div>
                <div onClick={() => scrollElement("features")} className="nav-list-item">Features</div>
                <div onClick={() => scrollElement("careers")} className="nav-list-item">Careers</div>
                <Link onClick={() => {
                          window.scrollTo({ top:0 , left: 0, behavior: 'smooth' });
                }} to="/privacy" className="nav-list-item">Privacy Policy</Link>
                <Link onClick={() => {
                          window.scrollTo({ top:0 , left: 0, behavior: 'smooth' });
                }} to="/terms-and-conditions" className="nav-list-item">Terms & Conditions</Link>
        </div>
          <div>UrbanVault Site No. 1781,</div>
          <div>19th Main Rd, Vanganahalli,</div>
          <div>1st Sector, HSR Layout,</div>
          <div>Bengaluru,</div>
          <div>Bengaluru Urban, Karnataka,</div>
          <div>560102</div>
          <a href='mailto:johndoe@fakeemail.com'>Email: care@frnd.app</a>

            <div className="company-title">© 2024 Cold Brew Tech Pvt. Ltd. All rights reserved</div>
        </div>
            <Button className={'footer-button-container'} style={{
              margin:' 32px 0 0'
            }} onPress={navigateToDownloadApk} textColor={'black'} backgroundColor={'white'} buttonText={'Download App'} leftImage={GoogleBadge} />
    </div>
  );
}

export default Footer;