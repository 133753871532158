export const LANGUAGE_DATA = {
  HEADER: {
    en: {
      home: 'Home',
      features: 'Features',
      career: 'Career',
      contactUs: 'Contact Us',
      transparency: 'Safety Center',
      starTrainer: 'Become Star Trainer',
      becomeGameHost: 'Become a Game Show Host',
      becomeFrndCeleb: 'Become a FRND Celeb',
      logout: 'Logout',
      blog: 'Blog'
    },
    hi: {
      starTrainer: 'स्टार ट्रेनर बनें'
    },
    tel: {
      starTrainer: 'స్టార్ ట్రైనర్ అవ్వండి'
    },
    tam: {
      starTrainer: 'நட்சத்திர பயிற்சியாளர் ஆகுங்கள்'
    }
  },
  HOME_HERO: {
    en: {
      heading: 'Social Discovery App for Bharat',
      cta: 'Download Now'
    },
    hi: {
      heading: 'Social Discovery App for Bharat',
      cta: 'Download Now'
    },
    tel: {
      heading: 'Social Discovery App for Bharat',
      cta: 'Download Now'
    },
    tam: {
      heading: 'Social Discovery App for Bharat',
      cta: 'Download Now'
    }
  },
  HOME_FEATURES: {
    en: {
      heading: 'What makes FRND special',
      f1: 'FRND-ship via Matchmaker on Audio Streaming',
      f2: 'Virtual Gifting driven Monetisation',
      f3: 'Moderated / Non-sleazy Community',
      f4: 'No pictures Only AI avatars',
      appTrust: 'Used and trusted by millions'
    },
    hi: {
      heading: 'What makes FRND special',
      f1: 'FRND-ship via Matchmaker on Audio Streaming',
      f2: 'Virtual Gifting driven Monetisation',
      f3: 'Moderated / Non-sleazy Community',
      f4: 'No pictures Only AI avatars',
      appTrust: 'Used and trusted by millions'
    },
    tel: {
      heading: 'What makes FRND special',
      f1: 'FRND-ship via Matchmaker on Audio Streaming',
      f2: 'Virtual Gifting driven Monetisation',
      f3: 'Moderated / Non-sleazy Community',
      f4: 'No pictures Only AI avatars',
      appTrust: 'Used and trusted by millions'
    },
    tam: {
      heading: 'What makes FRND special',
      f1: 'FRND-ship via Matchmaker on Audio Streaming',
      f2: 'Virtual Gifting driven Monetisation',
      f3: 'Moderated / Non-sleazy Community',
      f4: 'No pictures Only AI avatars',
      appTrust: 'Used and trusted by millions'
    }
  },
  HOME_TESTIMONIALS: {
    en: {
      heading: 'Our users love us',
      t1: 'APP ki Journey bohut achi thi, Pyar ho gya ish app se. busy life me thora sa waqt yaha bita kar maja aa jata hai,💝itne ache dost dene k liye🤗 Tq',
      t2: 'I think to be a part of FRND app is the one of the best decision of my life. i was struggling a lot and in the main covid time. I believe people should come play with us on FRND',
      t3: 'I really love this application 😊 I got good friends from this app 🙂Yahan Logon se baten karna bahut Achcha lagta hai. Fabulous app'
    },
    hi: {
      heading: 'Our users love us',
      t1: 'APP ki Journey bohut achi thi, Pyar ho gya ish app se. busy life me thora sa waqt yaha bita kar maja aa jata hai,💝itne ache dost dene k liye🤗 Tq',
      t2: 'I think to be a part of FRND app is the one of the best decision of my life. i was struggling a lot and in the main covid time. I believe people should come play with us on FRND',
      t3: 'I really love this application 😊 I got good friends from this app 🙂Yahan Logon se baten karna bahut Achcha lagta hai. Fabulous app'

    },
    tel: {
      heading: 'Our users love us',
      t1: 'APP ki Journey bohut achi thi, Pyar ho gya ish app se. busy life me thora sa waqt yaha bita kar maja aa jata hai,💝itne ache dost dene k liye🤗 Tq',
      t2: 'I think to be a part of FRND app is the one of the best decision of my life. i was struggling a lot and in the main covid time. I believe people should come play with us on FRND',
      t3: 'I really love this application 😊 I got good friends from this app 🙂Yahan Logon se baten karna bahut Achcha lagta hai. Fabulous app'

    },
    tam: {
      heading: 'Our users love us',
      t1: 'APP ki Journey bohut achi thi, Pyar ho gya ish app se. busy life me thora sa waqt yaha bita kar maja aa jata hai,💝itne ache dost dene k liye🤗 Tq',
      t2: 'I think to be a part of FRND app is the one of the best decision of my life. i was struggling a lot and in the main covid time. I believe people should come play with us on FRND',
      t3: 'I really love this application 😊 I got good friends from this app 🙂Yahan Logon se baten karna bahut Achcha lagta hai. Fabulous app'

    }
  },
  HOME_MEDIA: {
    en: {
      heading: 'Media Coverages',
      n1: 'BGMI creator Krafton leads $6.5 million funding round in FRND app',
      n2: 'PUBG-Maker KRAFTON Invests $6.5 Mn In Gamified friend making app FRND',
      n3: 'Battlegrounds Mobile India maker Krafton leads $6.5-million funding in audio friend making app FRND'
    },
    hi: {
      heading: 'Media Coverages',
      n1: 'BGMI creator Krafton leads $6.5 million funding round in FRND app',
      n2: 'PUBG-Maker KRAFTON Invests $6.5 Mn In Gamified friend making app FRND',
      n3: 'Battlegrounds Mobile India maker Krafton leads $6.5-million funding in audio friend making app FRND'
    },
    tel: {
      heading: 'Media Coverages',
      n1: 'BGMI creator Krafton leads $6.5 million funding round in FRND app',
      n2: 'PUBG-Maker KRAFTON Invests $6.5 Mn In Gamified friend making app FRND',
      n3: 'Battlegrounds Mobile India maker Krafton leads $6.5-million funding in audio friend making app FRND'
    },
    tam: {
      heading: 'Media Coverages',
      n1: 'BGMI creator Krafton leads $6.5 million funding round in FRND app',
      n2: 'PUBG-Maker KRAFTON Invests $6.5 Mn In Gamified friend making app FRND',
      n3: 'Battlegrounds Mobile India maker Krafton leads $6.5-million funding in audio friend making app FRND'
    }
  },
  HOME_CAREER: {
    en: {
      heading: 'We are looking to add stellar members to join our team',
      description: 'Email us at careers@frnd.app with your CV and Let\'s chat!',
      cta: 'See open positions',
      whyUs: 'Why us?',
      salary: 'Competitive Salary + ESOPs',
      leave: 'Flexible Leave Policy',
      health: 'Healthcare Insurance',
      weekend: 'Weekend cricket & fun events',
      flexibleTime: 'Flexible Working Hours',
      library: 'Library'
    },
    hi: {

    },
    tel: {

    },
    tam: {

    }
  },
  JOIN_US_HERO: {
    en: {
      heading: 'Transform Lives Now',
      subheading: 'Fill the form below to join our community of over 10,000 star trainers. Make FRNDs and Earn upto 1.25L per month!',
      cta: 'JOIN NOW'
    },
    hi: {
      heading: 'अब ज़िंदगियाँ बदले',
      subheading: 'जुड़िये 10,000 से भी ज्यादा स्टार ट्रेनर्स के साथ , ताकि आप लोगों को ग्रूम कर उनके जीवन में अर्थ जोड़ सके। हमसे अभी जुड़े !',
      cta: 'अभी जुड़िये '
    },
    tel: {
      heading: 'ఇప్పుడు జీవితాలను మార్చండి',
      subheading: 'వ్యక్తులకు శిక్షణ ఇవ్వడానికి & వారి జీవితాలకు అర్థాన్ని జోడించడానికి 10,000+ స్టార్ ట్రైనర్‌లలో చేరండి. ఇప్పుడు మాతో చేరండి!',
      cta: 'ఇప్పుడు చేరండి'
    },
    tam: {
      heading: 'இப்போதே வாழ்க்கையை மாற்றுங்கள்',
      subheading: '10 ஆயிரத்துக்கும் அதிகமான நட்சத்திர பயிற்சியாளர்களைச் சேர்த்து மக்களின் வாழ்க்கையை நன்முறைப்படுத்தி அவர்களின் வாழ்க்கைக்கு அர்த்தம் கொடுக்கவும். இப்போதே எங்களுடன் சேரவும்!',
      cta: 'இப்போதே சேரவும்'
    }
  },
  JOIN_US_BENEFITS: {
    en: {
      heading: 'Star Trainer Program',
      subHeading: 'Train People, Change Lives.',
      b1Title: 'Monetise your time',
      b1Text: 'Help people gain confidence and monetise your time. Some of our top trainers have won gifts of over 1.4 lakhs per month',
      b2Title: 'Community',
      b2Text: 'Join our exclusive community of passionate individuals from various fields! Here, you\'ll have the opportunity to learn, grow, and build relationships with like-minded people from all over the country.',
      b3Title: 'Anonymity',
      b3Text: 'User Data Protection and transparency are the foundational values of FRND. You can create a digital avatar & nickname for yourself, your personal information is always secured.'
    },
    hi: {
      heading: 'FRND ऐप पर एक स्टार ट्रेनर/लव गुरु बनें और करोड़ो ज़िंदगियाँ बदलने में मदद करें।',
      b1Title: 'पैसिव इनकम ',
      b1Text: 'आप लोगों की डेटिंग संबंधित मुद्दों को हल करने में मदद करते- करते पैसे कमा सकते है। हमारे स्टार ट्रेनर्स लगभग <b>1.25 लाख</b>  प्रति माह कमाते हैं!',
      b2Title: 'कम्युनिटी',
      b2Text: 'विभिन्न क्षेत्रों से पैशनेट व्यक्तियों के हमारे विशेष कम्युनिटी में शामिल हों जाइए! यहां, आपके पास देश भर के समान विचारधारा वाले लोगों के साथ सीखने, बढ़ने और रिलेशन बनाने का मौका मिलेगा।',
      b3Title: 'प्राइवेसी',
      b3Text: 'यूजर डेटा को सुरक्षित रखना और ट्रांसपेरेंसी FRND के मूलभूत मूल्य हैं। आप अपने लिए एक डिजिटल अवतार और निकनेम बना सकते हैं, आपकी व्यक्तिगत जानकारी हमेशा सुरक्षित रहती है।'
    },
    tel: {
      heading: 'FRND యాప్‌లో స్టార్ ట్రైనర్/లవ్ గురు అవ్వండి & కోట్లాది మంది జీవితాలను మార్చడంలో సహాయపడండి.',
      b1Title: 'నిష్క్రియాత్మక ఆదాయం',
      b1Text: 'మీరు డేటింగ్ సంబంధిత సమస్యలను పరిష్కరించడంలో వ్యక్తులకు సహాయం చేస్తున్నప్పుడు డబ్బు సంపాదించండి. మా స్టార్ ట్రైనర్‌లు నెలకు సగటున <b>1.25 లక్షలు</b> సంపాదిస్తారు!',
      b2Title: 'సంఘం',
      b2Text: 'వివిధ రంగాలకు చెందిన ఉద్వేగభరితమైన వ్యక్తుల మా ప్రత్యేక సంఘంలో చేరండి! ఇక్కడ, మీరు దేశం నలుమూలల నుండి ఇలాంటి ఆలోచనలు గల వ్యక్తులతో నేర్చుకునే, ఎదగడానికి మరియు సంబంధాలను ఏర్పరచుకోవడానికి అవకాశం ఉంటుంది.',
      b3Title: 'అనామకత్వం',
      b3Text: 'వినియోగదారు డేటా రక్షణ మరియు పారదర్శకత FRND యొక్క పునాది విలువలు. మీరు మీ కోసం డిజిటల్ అవతార్ & మారుపేరును సృష్టించవచ్చు, మీ వ్యక్తిగత సమాచారం ఎల్లప్పుడూ సురక్షితం.'
    },
    tam: {
      heading: 'FRND App-இல் நட்சத்திர பயிற்சியாளர்/லவ் குரு ஆகுங்கள் மற்றும் லட்சக்கணக்கானோரின் வாழ்க்கையை மாற்ற உதவுங்கள்',
      b1Title: 'மறைமுக வருமானம்',
      b1Text: 'டேட்டிங் தொடர்பான சிக்கல்களைத் தீர்க்க மக்களுக்கு உதவும்போது, பணம் சம்பாதிக்கவும். எங்களது நட்சத்திர பயிற்சியாளர்கள் மாதத்திற்கு சராசரியாக <b>1.25 லட்சம்</b> சம்பாதிக்கிறார்கள்!',
      b2Title: 'சமூகப்பிரிவு',
      b2Text: 'பல்வேறு துறைகளில் இருந்து ஆர்வமுள்ள நபர்களின் எங்கள் பிரத்யேக சமூகப்பிரிவில் சேரவும்! இங்கே, நாடு முழுவதிலுமிருந்து ஒரே மாதிரியான எண்ணம் உடையவர்களுடன் கற்றுக்கொள்ளவும், வளரவும், உறவுகளை வளர்த்துக் கொள்ளவும் உங்களுக்கு வாய்ப்பு கிடைக்கும்.',
      b3Title: 'தெரியாத',
      b3Text: 'பயனர் தரவு பாதுகாப்பு மற்றும் வெளிப்படைத்தன்மை என்பது FRND-ன் அடிப்படைத் தன்மைகள் ஆகும். உங்களுக்காக டிஜிட்டல் அவதார் மற்றும் புனைப்பெயரை உருவாக்கலாம், உங்கள் தனிப்பட்ட தகவல்கள் எப்போதும் பாதுகாக்கப்படுகின்றன.'
    }
  },
  JOIN_US_CHECKLIST: {
    en: {
      heading: 'What does it take to be a Star Trainer?',
      c1: 'Empathy + Great Communication',
      c2: 'Open-mindedness as no problem is big or small',
      c3: 'Patience is a great virtue!',
      c4: 'Preferred Language or Qualifications are not a barrier!',
      mission: '“Our mission is to empower Bharat\'s youth, instilling confidence and encouraging free self-expression in an engaging, enjoyable and a respectful way"'
    },
    hi: {
      heading: 'स्टार ट्रेनर बनने के लिए क्या होना चाहिए ?',
      c1: 'सहानुभूति + बेहतर कम्युनिकेशन ',
      c2: 'खुले विचार से उत्तर दें जैसे कोई समस्या बड़ी या छोटी नहीं है',
      c3: 'धैर्य एक महान गुण है!',
      c4: 'पसंदीदा भाषा या योग्यता कोई बाधा नहीं हैं!',
      mission: '“हमारा मिशन हैं युवा पीढ़ियों को सहायता प्रदान करके अधिक सुरक्षित भारत बनाना, जो संबंध से जुड़े चुनौतियों का सामना करते हैं, लेकिन ब्रेक-अप, बात करने में कठिनाई, असुरक्षा, शारीरिक संबंध से जुड़ी प्रॉब्लम और सामाजिक बाधाओं तक सीमित नहीं हैं।“'
    },
    tel: {
      heading: 'స్టార్ ట్రైనర్ కావడానికి ఏమి కావాలి?',
      c1: 'తాదాత్మ్యం + గొప్ప కమ్యూనికేషన్',
      c2: 'ఓపెన్-మైండెడ్నెస్ ఎందుకంటే సమస్య పెద్దది లేదా చిన్నది కాదు',
      c3: 'సహనం గొప్ప ధర్మం!',
      c4: 'ఇష్టపడే భాష లేదా అర్హతలు అవరోధం కాదు!',
      mission: '“మా లక్ష్యం భవిష్యత్ తరాల కోసం మరింత సమగ్ర భరట్‌ను సృష్టించడం, సంబంధాల సవాళ్లను ఎదుర్కొనే వ్యక్తులకు సహాయం అందించడం ద్వారా, విచ్ఛిన్నం, కమ్యూనికేషన్ ఇబ్బందులు, అభద్రత, సాన్నిహిత్యం సమస్యలు మరియు సామాజిక అడ్డంకులతో సహా పరిమితం కాదు.“'
    },
    tam: {
      heading: 'ஒரு நட்சத்திர பயிற்சியாளராக இருப்பதற்கு என்ன தேவை?',
      c1: 'பரிவு + சிறந்த தகவல் தொடர்பு',
      c2: 'மனம் திறந்து பேசினால் எந்தவொரு பிரச்னையும் பெரியதோ சிறியதோ இல்லை',
      c3: 'பொறுமை ஒரு சிறந்த நல்லொழுக்கம்!',
      c4: 'விருப்ப மொழி அல்லது தகுதிகள் ஒரு தடையில்லை',
      mission: '“பிரேக்-அப் ஆனவர்கள், தொடர்பில் சிக்கல், பாதுகாப்பின்மை, நெருக்கத்தில் பிரச்னை மற்றும் சமூக தடைகள் உள்ளிட்ட உறவுகள் தொடர்பான சவால்களை எதிர்கொள்பவர்களுக்கு உதவி வழங்குவதன் மூலம் வருங்கால சந்ததியினருக்கு அனைத்தையும் உள்ளடக்கிய இந்தியாவை உருவாக்குவதே எங்கள் நோக்கம்,“'
    }
  },
  JOIN_US_FAQS: {
    en: {
      heading: 'FAQs',
      q1: 'How do I Sign-up to be a Star Trainer? ',
      a1: 'To join this vibrant & energetic community of star trainers, complete the registration by scrolling up & filling the details in the application form. Our team will contact you & schedule a short interview call with you.\nIf you are selected, you will go through a training program, where FRND team will introduce you to the vast FRND community',
      q2: 'What is my role in impacting the community?',
      a2: 'As a Star Trainer, you will be coordinating with our team while connecting with thousands of users around the world and help them build confidence & become the best version of themselves.',
      q3: 'How will I monetise my content?',
      a3: 'The program works in a GIG Economy model where you will receive gifts for the time you spend helping your community. You will be able to redeem your gifts instantly to your UPI account, directly from your wallet from the FRND Application'
    },
    hi: {
      heading: 'पूछे जाने वाले प्रश्न',
      q1: 'मैं स्टार ट्रेनर बनने के लिए कैसे साइन-अप करूँ? ',
      a1: 'FRND स्टार ट्रेनर बनने के लिए, ऊपर स्क्रॉल करके हमारी वेबसाइट पर रजिस्टर करें। एक बार जब आप बेसिक रजिस्ट्रेशन पूरा कर लेते हैं, उसके बाद FRND एप्लिकेशन डाउनलोड करें और हमारी टीम अगले स्टेप्स के लिए 24 घंटे के भीतर आपसे संपर्क करेगी।',
      q2: 'कम्युनिटी को प्रभावित करने में मेरी क्या भूमिका होगी?',
      a2: 'एक स्टार ट्रेनर के रूप में, आप दुनिया भर के हजारों यूजर के साथ जुड़ने के दौरान हमारी टीम के साथ कोआर्डिनेट करेंगे और उन्हें अपने रिश्ते से संबंधित मुद्दों को हल करने में मदद करेंगे।',
      q3: 'मैं कैसे पैसे कमा सकता/सकती हूँ ?',
      a3: 'यह कार्यक्रम एक GIG Economy मॉडल में काम करता है जहां आपको अपने कम्युनिटी की मदद करने के समय के लिए भुगतान किया जाएगा। आप अपनी कमाई को तुरंत अपने यूपीआई खाते में, सीधे FRND एप्लिकेशन से अपने वॉलेट में डाल पाएंगे।'

    },
    tel: {
      heading: 'తరచుగా అడిగే ప్రశ్నలు',
      q1: 'స్టార్ ట్రైనర్‌గా ఉండటానికి నేను ఎలా సైన్ అప్ చేయాలి?',
      a1: 'FRND స్టార్ ట్రైనర్ కావడానికి, పైన స్క్రోల్ చేయడం ద్వారా మా వెబ్‌సైట్‌లో నమోదు చేయండి. మీరు ప్రాథమిక రిజిస్ట్రేషన్ పూర్తి చేసిన తర్వాత, FRND అప్లికేషన్‌ను డౌన్‌లోడ్ చేయండి & మా బృందం తదుపరి దశల కోసం 24 గంటల్లో మిమ్మల్ని సంప్రదిస్తుంది.',
      q2: 'సమాజాన్ని ప్రభావితం చేయడంలో నా పాత్ర ఏమిటి?',
      a2: 'స్టార్ ట్రైనర్‌గా, మీరు ప్రపంచవ్యాప్తంగా వేలాది మంది వినియోగదారులతో కనెక్ట్ అవుతున్నప్పుడు మరియు వారి సంబంధ సంబంధిత సమస్యలను పరిష్కరించడంలో వారికి సహాయపడటానికి మా బృందంతో సమన్వయం చేస్తారు.',
      q3: 'నేను ఎలా డబ్బు సంపాదిస్తాను?',
      a3: 'ప్రోగ్రామ్ గిగ్ ఎకానమీ మోడల్‌లో పనిచేస్తుంది, ఇక్కడ మీరు మీ సంఘానికి సహాయం చేసే సమయానికి మీకు చెల్లించబడుతుంది. మీరు మీ ఆదాయాలను మీ యుపిఐ ఖాతాకు తక్షణమే ఉపసంహరించుకోగలుగుతారు, నేరుగా మీ వాలెట్ నుండి FRND అప్లికేషన్ నుండి.'

    },
    tam: {
      heading: 'அடிக்கடி கேட்கப்படும் கேள்விகள்',
      q1: 'ஒரு நட்சத்திர பயிற்சியாளராக நான் எவ்வாறு பதிவு செய்வது?',
      a1: 'FRND-ன் நட்சத்திர பயற்சியாளராக மாற, மேலே தள்ளி எங்கள் இணையத்தில் பதிவு செய்யுங்கள். நீங்கள் அடிப்படை பதிவை முடித்தவுடன், FRND App-ஐ பதிவிறக்கம் செய்யுங்கள், அடுத்த கட்டத்திற்கு செல்ல எங்கள் குழுவினர் உங்களை 24 மணி நேரத்திற்குள் தொடர்பு கொள்வார்கள்.',
      q2: 'சமூகத்தில் தாக்கத்தை ஏற்படுத்த எனது பங்கு என்ன?',
      a2: 'ஒரு நட்சத்திர பயிற்சியாளராக, உலகெங்கிலும் உள்ள ஆயிரக்கணக்கான பயனர்களுடன் இணைக்கும் போது நீங்கள் எங்கள் குழுவினருடன் ஒருங்கிணைந்து செயல்படுவீர்கள், மேலும் அவர்களின் உறவுகள் தொடர்பான சிக்கலைத் தீர்க்க உதவுவீர்கள்.',
      q3: 'நான் எப்படி பணம் சம்பாதிக்க முடியும்?',
      a3: 'இந்தத் திட்டம் ஒரு கிக் பொருளாதார பாணியில் செயல்படும், அங்கு உங்கள் சமூகத்திற்கு உதவ நீங்கள் செலவழிக்கும் நேரத்திற்கு உங்களுக்கு பணம் வழங்கப்படும். FRND APP-ன் பணப்பையிலிருந்து உங்கள் யுபிஐ கணக்கின் வாயிலாக உடனடியாக உங்கள் வருவாயை எடுக்க முடியும்.'

    }
  },
  JOIN_US_FORM: {
    en: {
      heading: 'Join Us Now!',
      subheading: 'Please share your details and our team will contact you in the next 24 hours.',
      name: '*Name',
      number: '*Whatsapp Number',
      referredBy: '*Referred By',
      gender: 'Gender',
      male: 'Male',
      female: 'Female',
      other: 'Other',
      submitButton: 'Submit'
    },
    hi: {
      heading: 'हमसे अभी जुड़े !',
      subheading: 'कृपया अपनी डिटेल्स यहाँ शेयर करें और हमारी टीम अगले 24 घंटों में आपसे संपर्क करेगी।',
      name: '*नाम',
      number: '*व्हाट्सएप नंबर',
      referredBy: '*रेफ़र्ड बाय',
      gender: 'लिंग:',
      male: 'पुरुष',
      female: 'महिला',
      other: 'अन्य',
      submitButton: 'जमा करे'

    },
    tel: {
      heading: 'ఇప్పుడు మాతో చేరండి!',
      subheading: 'దయచేసి మీ వివరాలను పంచుకోండి మరియు రాబోయే 24 గంటల్లో మా బృందం మిమ్మల్ని సంప్రదిస్తుంది.',
      name: '*పేరు',
      number: '*వాట్సాప్ నంబర్',
      referredBy: '*సంప్రదించబడిన',
      gender: 'లింగం:',
      male: 'పురుషుడు',
      female: 'స్త్రీ',
      other: 'ఇతర',
      submitButton: 'సమర్పించండి'

    },
    tam: {
      heading: 'இப்போதே எங்களுடன் சேரவும்!',
      subheading: 'தயவுசெய்து, உங்கள் விபரங்களை பகிர்ந்து கொள்ளுங்கள், அடுத்த 24 மணி நேரத்தில் எங்கள் குழுவினர் உங்களைத் தொடர்பு கொள்வார்கள்.',
      name: '*பெயர்',
      number: '*வாட்ஸ்அப் எண்',
      referredBy: '*பரிந்துரைக்கப்பட்ட',
      gender: 'பாலினம்:',
      male: 'ஆண்',
      female: 'பெண்',
      other: 'மற்றவை',
      submitButton: 'சமர்ப்பி'

    }
  },
  GAME_EXPERT_JOIN_US_HERO: {
    en: {
      heading: 'Become the next Big Gaming Influencer!',
      subheading: 'Fill the form below to join our community of over 10,000 Game Show Hosts. Host Engaging Games on FRND, build your fan-following and earn up to 1.25L per month!',
      cta: 'JOIN NOW'
    },
    hi: {
      heading: 'अब ज़िंदगियाँ बदले',
      subheading: 'जुड़िये 10,000 से भी ज्यादा स्टार ट्रेनर्स के साथ , ताकि आप लोगों को ग्रूम कर उनके जीवन में अर्थ जोड़ सके। हमसे अभी जुड़े !',
      cta: 'अभी जुड़िये '
    },
    tel: {
      heading: 'ఇప్పుడు జీవితాలను మార్చండి',
      subheading: 'వ్యక్తులకు శిక్షణ ఇవ్వడానికి & వారి జీవితాలకు అర్థాన్ని జోడించడానికి 10,000+ స్టార్ ట్రైనర్‌లలో చేరండి. ఇప్పుడు మాతో చేరండి!',
      cta: 'ఇప్పుడు చేరండి'
    },
    tam: {
      heading: 'இப்போதே வாழ்க்கையை மாற்றுங்கள்',
      subheading: '10 ஆயிரத்துக்கும் அதிகமான நட்சத்திர பயிற்சியாளர்களைச் சேர்த்து மக்களின் வாழ்க்கையை நன்முறைப்படுத்தி அவர்களின் வாழ்க்கைக்கு அர்த்தம் கொடுக்கவும். இப்போதே எங்களுடன் சேரவும்!',
      cta: 'இப்போதே சேரவும்'
    }
  },
  CELEB_JOIN_US_HERO: {
    en: {
      heading: 'Become a Celebrity on FRND App!',
      subheading: 'Fill the form below to join our community of 10,000+ creators. \nMonetize your time and get discovered by millions!',
      cta: 'JOIN NOW'
    },
    hi: {
      heading: 'अब ज़िंदगियाँ बदले',
      subheading: 'जुड़िये 10,000 से भी ज्यादा स्टार ट्रेनर्स के साथ , ताकि आप लोगों को ग्रूम कर उनके जीवन में अर्थ जोड़ सके। हमसे अभी जुड़े !',
      cta: 'अभी जुड़िये '
    },
    tel: {
      heading: 'ఇప్పుడు జీవితాలను మార్చండి',
      subheading: 'వ్యక్తులకు శిక్షణ ఇవ్వడానికి & వారి జీవితాలకు అర్థాన్ని జోడించడానికి 10,000+ స్టార్ ట్రైనర్‌లలో చేరండి. ఇప్పుడు మాతో చేరండి!',
      cta: 'ఇప్పుడు చేరండి'
    },
    tam: {
      heading: 'இப்போதே வாழ்க்கையை மாற்றுங்கள்',
      subheading: '10 ஆயிரத்துக்கும் அதிகமான நட்சத்திர பயிற்சியாளர்களைச் சேர்த்து மக்களின் வாழ்க்கையை நன்முறைப்படுத்தி அவர்களின் வாழ்க்கைக்கு அர்த்தம் கொடுக்கவும். இப்போதே எங்களுடன் சேரவும்!',
      cta: 'இப்போதே சேரவும்'
    }
  },
  GAME_EXPERT_JOIN_US_BENEFITS: {
    en: {
      heading: 'Game Show Host Program',
      subHeading: 'Train People. Change Lives',
      b1Title: 'Passive Income',
      b1Text: 'Host fun games like Dumb Charades, Ludo, Carrom, Card Games, Antakshari and many more. Our Star Game Show Hosts earn an average of 1.25 Lacs ₹ per month.',
      b2Title: 'Community',
      b2Text: 'Join our exclusive community of passionate <b>Game Hosts & Gamers</b> from various backgrounds! Here, you\'ll have the opportunity to learn, grow, and build relationships with like-minded people from all over the world.',
      b3Title: 'Popularity',
      b3Text: 'With over 100 Million Downloads, FRND has become one of the biggest Social Gaming platforms in the world. Now you have the opportunity to garner your fan following & become “The next Big Gaming Influencer”'
    },
    hi: {
      heading: 'FRND ऐप पर एक स्टार ट्रेनर/लव गुरु बनें और करोड़ो ज़िंदगियाँ बदलने में मदद करें।',
      b1Title: 'पैसिव इनकम ',
      b1Text: 'आप लोगों की डेटिंग संबंधित मुद्दों को हल करने में मदद करते- करते पैसे कमा सकते है। हमारे स्टार ट्रेनर्स लगभग <b>1.25 लाख</b>  प्रति माह कमाते हैं!',
      b2Title: 'कम्युनिटी',
      b2Text: 'विभिन्न क्षेत्रों से पैशनेट व्यक्तियों के हमारे विशेष कम्युनिटी में शामिल हों जाइए! यहां, आपके पास देश भर के समान विचारधारा वाले लोगों के साथ सीखने, बढ़ने और रिलेशन बनाने का मौका मिलेगा।',
      b3Title: 'प्राइवेसी',
      b3Text: 'यूजर डेटा को सुरक्षित रखना और ट्रांसपेरेंसी FRND के मूलभूत मूल्य हैं। आप अपने लिए एक डिजिटल अवतार और निकनेम बना सकते हैं, आपकी व्यक्तिगत जानकारी हमेशा सुरक्षित रहती है।'
    },
    tel: {
      heading: 'FRND యాప్‌లో స్టార్ ట్రైనర్/లవ్ గురు అవ్వండి & కోట్లాది మంది జీవితాలను మార్చడంలో సహాయపడండి.',
      b1Title: 'నిష్క్రియాత్మక ఆదాయం',
      b1Text: 'మీరు డేటింగ్ సంబంధిత సమస్యలను పరిష్కరించడంలో వ్యక్తులకు సహాయం చేస్తున్నప్పుడు డబ్బు సంపాదించండి. మా స్టార్ ట్రైనర్‌లు నెలకు సగటున <b>1.25 లక్షలు</b> సంపాదిస్తారు!',
      b2Title: 'సంఘం',
      b2Text: 'వివిధ రంగాలకు చెందిన ఉద్వేగభరితమైన వ్యక్తుల మా ప్రత్యేక సంఘంలో చేరండి! ఇక్కడ, మీరు దేశం నలుమూలల నుండి ఇలాంటి ఆలోచనలు గల వ్యక్తులతో నేర్చుకునే, ఎదగడానికి మరియు సంబంధాలను ఏర్పరచుకోవడానికి అవకాశం ఉంటుంది.',
      b3Title: 'అనామకత్వం',
      b3Text: 'వినియోగదారు డేటా రక్షణ మరియు పారదర్శకత FRND యొక్క పునాది విలువలు. మీరు మీ కోసం డిజిటల్ అవతార్ & మారుపేరును సృష్టించవచ్చు, మీ వ్యక్తిగత సమాచారం ఎల్లప్పుడూ సురక్షితం.'
    },
    tam: {
      heading: 'FRND App-இல் நட்சத்திர பயிற்சியாளர்/லவ் குரு ஆகுங்கள் மற்றும் லட்சக்கணக்கானோரின் வாழ்க்கையை மாற்ற உதவுங்கள்',
      b1Title: 'மறைமுக வருமானம்',
      b1Text: 'டேட்டிங் தொடர்பான சிக்கல்களைத் தீர்க்க மக்களுக்கு உதவும்போது, பணம் சம்பாதிக்கவும். எங்களது நட்சத்திர பயிற்சியாளர்கள் மாதத்திற்கு சராசரியாக <b>1.25 லட்சம்</b> சம்பாதிக்கிறார்கள்!',
      b2Title: 'சமூகப்பிரிவு',
      b2Text: 'பல்வேறு துறைகளில் இருந்து ஆர்வமுள்ள நபர்களின் எங்கள் பிரத்யேக சமூகப்பிரிவில் சேரவும்! இங்கே, நாடு முழுவதிலுமிருந்து ஒரே மாதிரியான எண்ணம் உடையவர்களுடன் கற்றுக்கொள்ளவும், வளரவும், உறவுகளை வளர்த்துக் கொள்ளவும் உங்களுக்கு வாய்ப்பு கிடைக்கும்.',
      b3Title: 'தெரியாத',
      b3Text: 'பயனர் தரவு பாதுகாப்பு மற்றும் வெளிப்படைத்தன்மை என்பது FRND-ன் அடிப்படைத் தன்மைகள் ஆகும். உங்களுக்காக டிஜிட்டல் அவதார் மற்றும் புனைப்பெயரை உருவாக்கலாம், உங்கள் தனிப்பட்ட தகவல்கள் எப்போதும் பாதுகாக்கப்படுகின்றன.'
    }
  },
  CELEB_JOIN_US_BENEFITS: {
    en: {
      heading: 'Want to become a celeb on FRND App? ',
      subHeading: 'Here is what we look for:',
      b1Title: 'Public Social Media profile with high quality content and good engagement.',
      b2Title: 'Having a big following on popular social media platforms is a plus.',
      b2Text1: 'Instagram/Snapchat - Minimum 2000 Followers',
      b2Text2: 'Youtube - Minimum 1000 Subscribers',
      b3Title: 'Empathy, Enthusiasm, Ability to have engaging conversations.',
      b4Title: 'Willingness to contribute to social good and create impact in the community.'
    },
    hi: {
      heading: 'FRND ऐप पर एक स्टार ट्रेनर/लव गुरु बनें और करोड़ो ज़िंदगियाँ बदलने में मदद करें।',
      b1Title: 'पैसिव इनकम ',
      b1Text: 'आप लोगों की डेटिंग संबंधित मुद्दों को हल करने में मदद करते- करते पैसे कमा सकते है। हमारे स्टार ट्रेनर्स लगभग <b>1.25 लाख</b>  प्रति माह कमाते हैं!',
      b2Title: 'कम्युनिटी',
      b2Text: 'विभिन्न क्षेत्रों से पैशनेट व्यक्तियों के हमारे विशेष कम्युनिटी में शामिल हों जाइए! यहां, आपके पास देश भर के समान विचारधारा वाले लोगों के साथ सीखने, बढ़ने और रिलेशन बनाने का मौका मिलेगा।',
      b3Title: 'प्राइवेसी',
      b3Text: 'यूजर डेटा को सुरक्षित रखना और ट्रांसपेरेंसी FRND के मूलभूत मूल्य हैं। आप अपने लिए एक डिजिटल अवतार और निकनेम बना सकते हैं, आपकी व्यक्तिगत जानकारी हमेशा सुरक्षित रहती है।'
    },
    tel: {
      heading: 'FRND యాప్‌లో స్టార్ ట్రైనర్/లవ్ గురు అవ్వండి & కోట్లాది మంది జీవితాలను మార్చడంలో సహాయపడండి.',
      b1Title: 'నిష్క్రియాత్మక ఆదాయం',
      b1Text: 'మీరు డేటింగ్ సంబంధిత సమస్యలను పరిష్కరించడంలో వ్యక్తులకు సహాయం చేస్తున్నప్పుడు డబ్బు సంపాదించండి. మా స్టార్ ట్రైనర్‌లు నెలకు సగటున <b>1.25 లక్షలు</b> సంపాదిస్తారు!',
      b2Title: 'సంఘం',
      b2Text: 'వివిధ రంగాలకు చెందిన ఉద్వేగభరితమైన వ్యక్తుల మా ప్రత్యేక సంఘంలో చేరండి! ఇక్కడ, మీరు దేశం నలుమూలల నుండి ఇలాంటి ఆలోచనలు గల వ్యక్తులతో నేర్చుకునే, ఎదగడానికి మరియు సంబంధాలను ఏర్పరచుకోవడానికి అవకాశం ఉంటుంది.',
      b3Title: 'అనామకత్వం',
      b3Text: 'వినియోగదారు డేటా రక్షణ మరియు పారదర్శకత FRND యొక్క పునాది విలువలు. మీరు మీ కోసం డిజిటల్ అవతార్ & మారుపేరును సృష్టించవచ్చు, మీ వ్యక్తిగత సమాచారం ఎల్లప్పుడూ సురక్షితం.'
    },
    tam: {
      heading: 'FRND App-இல் நட்சத்திர பயிற்சியாளர்/லவ் குரு ஆகுங்கள் மற்றும் லட்சக்கணக்கானோரின் வாழ்க்கையை மாற்ற உதவுங்கள்',
      b1Title: 'மறைமுக வருமானம்',
      b1Text: 'டேட்டிங் தொடர்பான சிக்கல்களைத் தீர்க்க மக்களுக்கு உதவும்போது, பணம் சம்பாதிக்கவும். எங்களது நட்சத்திர பயிற்சியாளர்கள் மாதத்திற்கு சராசரியாக <b>1.25 லட்சம்</b> சம்பாதிக்கிறார்கள்!',
      b2Title: 'சமூகப்பிரிவு',
      b2Text: 'பல்வேறு துறைகளில் இருந்து ஆர்வமுள்ள நபர்களின் எங்கள் பிரத்யேக சமூகப்பிரிவில் சேரவும்! இங்கே, நாடு முழுவதிலுமிருந்து ஒரே மாதிரியான எண்ணம் உடையவர்களுடன் கற்றுக்கொள்ளவும், வளரவும், உறவுகளை வளர்த்துக் கொள்ளவும் உங்களுக்கு வாய்ப்பு கிடைக்கும்.',
      b3Title: 'தெரியாத',
      b3Text: 'பயனர் தரவு பாதுகாப்பு மற்றும் வெளிப்படைத்தன்மை என்பது FRND-ன் அடிப்படைத் தன்மைகள் ஆகும். உங்களுக்காக டிஜிட்டல் அவதார் மற்றும் புனைப்பெயரை உருவாக்கலாம், உங்கள் தனிப்பட்ட தகவல்கள் எப்போதும் பாதுகாக்கப்படுகின்றன.'
    }
  },
  GAME_EXPERT_JOIN_US_CHECKLIST: {
    en: {
      heading: 'What does it take to be a Game Show Host at FRND?',
      c1: 'You are a born entertainer 🥳',
      c2: 'Open-mindedness as a great host can engage anyone! 👩🏻‍💼',
      c3: 'You are confident in your abilities & express yourself freely! 😎',
      c4: 'Preferred Language or Qualifications are not a barrier! ✅',
      mission: '“Our mission is to empower Bharat by offering support for relationship challenges, communication issues, insecurities and social obstacles, fostering a more inclusive society"'
    },
    hi: {
      heading: 'स्टार ट्रेनर बनने के लिए क्या होना चाहिए ?',
      c1: 'सहानुभूति + बेहतर कम्युनिकेशन ',
      c2: 'खुले विचार से उत्तर दें जैसे कोई समस्या बड़ी या छोटी नहीं है',
      c3: 'धैर्य एक महान गुण है!',
      c4: 'पसंदीदा भाषा या योग्यता कोई बाधा नहीं हैं!',
      mission: '“हमारा मिशन हैं युवा पीढ़ियों को सहायता प्रदान करके अधिक सुरक्षित भारत बनाना, जो संबंध से जुड़े चुनौतियों का सामना करते हैं, लेकिन ब्रेक-अप, बात करने में कठिनाई, असुरक्षा, शारीरिक संबंध से जुड़ी प्रॉब्लम और सामाजिक बाधाओं तक सीमित नहीं हैं।“'
    },
    tel: {
      heading: 'స్టార్ ట్రైనర్ కావడానికి ఏమి కావాలి?',
      c1: 'తాదాత్మ్యం + గొప్ప కమ్యూనికేషన్',
      c2: 'ఓపెన్-మైండెడ్నెస్ ఎందుకంటే సమస్య పెద్దది లేదా చిన్నది కాదు',
      c3: 'సహనం గొప్ప ధర్మం!',
      c4: 'ఇష్టపడే భాష లేదా అర్హతలు అవరోధం కాదు!',
      mission: '“మా లక్ష్యం భవిష్యత్ తరాల కోసం మరింత సమగ్ర భరట్‌ను సృష్టించడం, సంబంధాల సవాళ్లను ఎదుర్కొనే వ్యక్తులకు సహాయం అందించడం ద్వారా, విచ్ఛిన్నం, కమ్యూనికేషన్ ఇబ్బందులు, అభద్రత, సాన్నిహిత్యం సమస్యలు మరియు సామాజిక అడ్డంకులతో సహా పరిమితం కాదు.“'
    },
    tam: {
      heading: 'ஒரு நட்சத்திர பயிற்சியாளராக இருப்பதற்கு என்ன தேவை?',
      c1: 'பரிவு + சிறந்த தகவல் தொடர்பு',
      c2: 'மனம் திறந்து பேசினால் எந்தவொரு பிரச்னையும் பெரியதோ சிறியதோ இல்லை',
      c3: 'பொறுமை ஒரு சிறந்த நல்லொழுக்கம்!',
      c4: 'விருப்ப மொழி அல்லது தகுதிகள் ஒரு தடையில்லை',
      mission: '“பிரேக்-அப் ஆனவர்கள், தொடர்பில் சிக்கல், பாதுகாப்பின்மை, நெருக்கத்தில் பிரச்னை மற்றும் சமூக தடைகள் உள்ளிட்ட உறவுகள் தொடர்பான சவால்களை எதிர்கொள்பவர்களுக்கு உதவி வழங்குவதன் மூலம் வருங்கால சந்ததியினருக்கு அனைத்தையும் உள்ளடக்கிய இந்தியாவை உருவாக்குவதே எங்கள் நோக்கம்,“'
    }
  },
  CELEB_MOTTO: {
    en: {
      motto: 'We are building the biggest community of young, energetic & confident individuals who can guide our 20Million+ users on confidence-building, personality development, and common relationship problems.',
      title1: 'Monetization for time spent',
      description1: 'Right from day one, creators are monetized on the FRND App and can earn 1500rs per hour. You will receive virtual gifts for every minute of your interaction with users. Our top creators earn upwards of 1.5L per month. The earning potential is endless. Sky is the limit!',
      title2: 'Community Building',
      description2: 'Interacting with followers on other social media apps is not as financially lucrative or as safe as on the FRND App. Your existing followers can interact with you through the FRND App, where you will be able to monetize your following directly and have conversations in a moderated environment without sharing personal details. FRND has implemented AI for your safety, such as face detection, voice verification, etc.',
      title3: 'Discoverability',
      description3: 'FRND has over 20M+ users. You will be positioned in our flagship segment called LoveSkool giving you maximum visibility and a chance to showcase your portfolio as a creator which allows FRND users to explore your social media handles and inturn grow your following on social media platforms.',
    },
    hi: {
      motto: 'स्टार ट्रेनर बनने के लिए क्या होना चाहिए ?',
      title1: 'सहानुभूति + बेहतर कम्युनिकेशन ',
      description1: 'खुले विचार से उत्तर दें जैसे कोई समस्या बड़ी या छोटी नहीं है',
      title2: 'Community Building',
      description2: 'Interacting with followers on other social media apps is not financially lucrative or as safe as FRND App. Your existing followed can interact with you through FRND App where you will be able to monetize your following directly and have conversations in a moderated environment without sharing personal details. FRND has implemented the AI for your safety for eg- face detection , voice verification etc.',
      title3: 'Discoverability',
      description3: 'FRND has over 20M+ users. You will be positioned in our flagship segment called LoveSkool giving you maximum visibility and a chance to showcase your portfolio as a creator which allows FRND users to explore your social media handles and inturn grow your following on social media platforms.',
    },
    tel: {
      motto: 'స్టార్ ట్రైనర్ కావడానికి ఏమి కావాలి?',
      title1: 'తాదాత్మ్యం + గొప్ప కమ్యూనికేషన్',
      description1: 'ఓపెన్-మైండెడ్నెస్ ఎందుకంటే సమస్య పెద్దది లేదా చిన్నది కాదు',
      title2: 'Community Building',
      description2: 'Interacting with followers on other social media apps is not financially lucrative or as safe as FRND App. Your existing followed can interact with you through FRND App where you will be able to monetize your following directly and have conversations in a moderated environment without sharing personal details. FRND has implemented the AI for your safety for eg- face detection , voice verification etc.',
      title3: 'Discoverability',
      description3: 'FRND has over 20M+ users. You will be positioned in our flagship segment called LoveSkool giving you maximum visibility and a chance to showcase your portfolio as a creator which allows FRND users to explore your social media handles and inturn grow your following on social media platforms.',
    },
    tam: {
      motto: 'ஒரு நட்சத்திர பயிற்சியாளராக இருப்பதற்கு என்ன தேவை?',
      title1: 'பரிவு + சிறந்த தகவல் தொடர்பு',
      description1: 'மனம் திறந்து பேசினால் எந்தவொரு பிரச்னையும் பெரியதோ சிறியதோ இல்லை',
      title2: 'Community Building',
      description2: 'Interacting with followers on other social media apps is not financially lucrative or as safe as FRND App. Your existing followed can interact with you through FRND App where you will be able to monetize your following directly and have conversations in a moderated environment without sharing personal details. FRND has implemented the AI for your safety for eg- face detection , voice verification etc.',
      title3: 'Discoverability',
      description3: 'FRND has over 20M+ users. You will be positioned in our flagship segment called LoveSkool giving you maximum visibility and a chance to showcase your portfolio as a creator which allows FRND users to explore your social media handles and inturn grow your following on social media platforms.',
    }
  },
  CELEB_JOIN_US_FAQS: {
    en: {
      heading: 'FAQs',
      q1: 'How do I Sign up to be a LoveSkool expert ?',
      a1: 'To become a FRND LoveSkool expert, register on our website by scrolling up to the top of the page. Once you’ve completed the basic registration, download the FRND Application & our team will contact you within 24 hours for the next steps.',
      q2: 'What is my role in impacting the community?',
      a2: 'As a LoveSkool expert, you will guide our community of over 20 million users to become a better version of themselves. You can create a real impact by mentoring users one-on-one on personality development and lifestyle issues.',
      q3: 'How will I earn money from the following I make?',
      a3: 'The program works in a GIG Economy model where you will be paid for the number of minutes you interact with user you assist. You will be able to withdraw your earnings instantly to your UPI account from your FRND wallet.'
    },
    hi: {
      heading: 'पूछे जाने वाले प्रश्न',
      q1: 'मैं स्टार ट्रेनर बनने के लिए कैसे साइन-अप करूँ? ',
      a1: 'FRND स्टार ट्रेनर बनने के लिए, ऊपर स्क्रॉल करके हमारी वेबसाइट पर रजिस्टर करें। एक बार जब आप बेसिक रजिस्ट्रेशन पूरा कर लेते हैं, उसके बाद FRND एप्लिकेशन डाउनलोड करें और हमारी टीम अगले स्टेप्स के लिए 24 घंटे के भीतर आपसे संपर्क करेगी।',
      q2: 'कम्युनिटी को प्रभावित करने में मेरी क्या भूमिका होगी?',
      a2: 'एक स्टार ट्रेनर के रूप में, आप दुनिया भर के हजारों यूजर के साथ जुड़ने के दौरान हमारी टीम के साथ कोआर्डिनेट करेंगे और उन्हें अपने रिश्ते से संबंधित मुद्दों को हल करने में मदद करेंगे।',
      q3: 'मैं कैसे पैसे कमा सकता/सकती हूँ ?',
      a3: 'यह कार्यक्रम एक GIG Economy मॉडल में काम करता है जहां आपको अपने कम्युनिटी की मदद करने के समय के लिए भुगतान किया जाएगा। आप अपनी कमाई को तुरंत अपने यूपीआई खाते में, सीधे FRND एप्लिकेशन से अपने वॉलेट में डाल पाएंगे।'

    },
    tel: {
      heading: 'తరచుగా అడిగే ప్రశ్నలు',
      q1: 'స్టార్ ట్రైనర్‌గా ఉండటానికి నేను ఎలా సైన్ అప్ చేయాలి?',
      a1: 'FRND స్టార్ ట్రైనర్ కావడానికి, పైన స్క్రోల్ చేయడం ద్వారా మా వెబ్‌సైట్‌లో నమోదు చేయండి. మీరు ప్రాథమిక రిజిస్ట్రేషన్ పూర్తి చేసిన తర్వాత, FRND అప్లికేషన్‌ను డౌన్‌లోడ్ చేయండి & మా బృందం తదుపరి దశల కోసం 24 గంటల్లో మిమ్మల్ని సంప్రదిస్తుంది.',
      q2: 'సమాజాన్ని ప్రభావితం చేయడంలో నా పాత్ర ఏమిటి?',
      a2: 'స్టార్ ట్రైనర్‌గా, మీరు ప్రపంచవ్యాప్తంగా వేలాది మంది వినియోగదారులతో కనెక్ట్ అవుతున్నప్పుడు మరియు వారి సంబంధ సంబంధిత సమస్యలను పరిష్కరించడంలో వారికి సహాయపడటానికి మా బృందంతో సమన్వయం చేస్తారు.',
      q3: 'నేను ఎలా డబ్బు సంపాదిస్తాను?',
      a3: 'ప్రోగ్రామ్ గిగ్ ఎకానమీ మోడల్‌లో పనిచేస్తుంది, ఇక్కడ మీరు మీ సంఘానికి సహాయం చేసే సమయానికి మీకు చెల్లించబడుతుంది. మీరు మీ ఆదాయాలను మీ యుపిఐ ఖాతాకు తక్షణమే ఉపసంహరించుకోగలుగుతారు, నేరుగా మీ వాలెట్ నుండి FRND అప్లికేషన్ నుండి.'

    },
    tam: {
      heading: 'அடிக்கடி கேட்கப்படும் கேள்விகள்',
      q1: 'ஒரு நட்சத்திர பயிற்சியாளராக நான் எவ்வாறு பதிவு செய்வது?',
      a1: 'FRND-ன் நட்சத்திர பயற்சியாளராக மாற, மேலே தள்ளி எங்கள் இணையத்தில் பதிவு செய்யுங்கள். நீங்கள் அடிப்படை பதிவை முடித்தவுடன், FRND App-ஐ பதிவிறக்கம் செய்யுங்கள், அடுத்த கட்டத்திற்கு செல்ல எங்கள் குழுவினர் உங்களை 24 மணி நேரத்திற்குள் தொடர்பு கொள்வார்கள்.',
      q2: 'சமூகத்தில் தாக்கத்தை ஏற்படுத்த எனது பங்கு என்ன?',
      a2: 'ஒரு நட்சத்திர பயிற்சியாளராக, உலகெங்கிலும் உள்ள ஆயிரக்கணக்கான பயனர்களுடன் இணைக்கும் போது நீங்கள் எங்கள் குழுவினருடன் ஒருங்கிணைந்து செயல்படுவீர்கள், மேலும் அவர்களின் உறவுகள் தொடர்பான சிக்கலைத் தீர்க்க உதவுவீர்கள்.',
      q3: 'நான் எப்படி பணம் சம்பாதிக்க முடியும்?',
      a3: 'இந்தத் திட்டம் ஒரு கிக் பொருளாதார பாணியில் செயல்படும், அங்கு உங்கள் சமூகத்திற்கு உதவ நீங்கள் செலவழிக்கும் நேரத்திற்கு உங்களுக்கு பணம் வழங்கப்படும். FRND APP-ன் பணப்பையிலிருந்து உங்கள் யுபிஐ கணக்கின் வாயிலாக உடனடியாக உங்கள் வருவாயை எடுக்க முடியும்.'

    }
  },
  GAME_EXPERT_JOIN_US_FAQS: {
    en: {
      heading: 'FAQs',
      q1: 'How do I Sign up to be a Game Show Host?  ',
      a1: 'To become a FRND Game Show Host, register on our website by scrolling up to the top of the page. Once you’ve completed the basic registration, download the FRND Application & our team will contact you within 12 hours for the next steps.',
      q2: 'What is my role in impacting the community?',
      a2: 'As a Game Show Host, you will be coordinating with our team while connecting with thousands of users around the world to entertain & engage them. The Game Hosts with the biggest following will become “The next Biggest Gaming Influencers!”',
      q3: 'How will I earn money from the following I make?',
      a3: 'The program works in a GIG Economy model where you will be paid for the Games you Host on the application. You will be able to withdraw your earnings instantly to your UPI account, directly from your wallet from the FRND Application.'
    },
    hi: {
      heading: 'पूछे जाने वाले प्रश्न',
      q1: 'मैं स्टार ट्रेनर बनने के लिए कैसे साइन-अप करूँ? ',
      a1: 'FRND स्टार ट्रेनर बनने के लिए, ऊपर स्क्रॉल करके हमारी वेबसाइट पर रजिस्टर करें। एक बार जब आप बेसिक रजिस्ट्रेशन पूरा कर लेते हैं, उसके बाद FRND एप्लिकेशन डाउनलोड करें और हमारी टीम अगले स्टेप्स के लिए 24 घंटे के भीतर आपसे संपर्क करेगी।',
      q2: 'कम्युनिटी को प्रभावित करने में मेरी क्या भूमिका होगी?',
      a2: 'एक स्टार ट्रेनर के रूप में, आप दुनिया भर के हजारों यूजर के साथ जुड़ने के दौरान हमारी टीम के साथ कोआर्डिनेट करेंगे और उन्हें अपने रिश्ते से संबंधित मुद्दों को हल करने में मदद करेंगे।',
      q3: 'मैं कैसे पैसे कमा सकता/सकती हूँ ?',
      a3: 'यह कार्यक्रम एक GIG Economy मॉडल में काम करता है जहां आपको अपने कम्युनिटी की मदद करने के समय के लिए भुगतान किया जाएगा। आप अपनी कमाई को तुरंत अपने यूपीआई खाते में, सीधे FRND एप्लिकेशन से अपने वॉलेट में डाल पाएंगे।'

    },
    tel: {
      heading: 'తరచుగా అడిగే ప్రశ్నలు',
      q1: 'స్టార్ ట్రైనర్‌గా ఉండటానికి నేను ఎలా సైన్ అప్ చేయాలి?',
      a1: 'FRND స్టార్ ట్రైనర్ కావడానికి, పైన స్క్రోల్ చేయడం ద్వారా మా వెబ్‌సైట్‌లో నమోదు చేయండి. మీరు ప్రాథమిక రిజిస్ట్రేషన్ పూర్తి చేసిన తర్వాత, FRND అప్లికేషన్‌ను డౌన్‌లోడ్ చేయండి & మా బృందం తదుపరి దశల కోసం 24 గంటల్లో మిమ్మల్ని సంప్రదిస్తుంది.',
      q2: 'సమాజాన్ని ప్రభావితం చేయడంలో నా పాత్ర ఏమిటి?',
      a2: 'స్టార్ ట్రైనర్‌గా, మీరు ప్రపంచవ్యాప్తంగా వేలాది మంది వినియోగదారులతో కనెక్ట్ అవుతున్నప్పుడు మరియు వారి సంబంధ సంబంధిత సమస్యలను పరిష్కరించడంలో వారికి సహాయపడటానికి మా బృందంతో సమన్వయం చేస్తారు.',
      q3: 'నేను ఎలా డబ్బు సంపాదిస్తాను?',
      a3: 'ప్రోగ్రామ్ గిగ్ ఎకానమీ మోడల్‌లో పనిచేస్తుంది, ఇక్కడ మీరు మీ సంఘానికి సహాయం చేసే సమయానికి మీకు చెల్లించబడుతుంది. మీరు మీ ఆదాయాలను మీ యుపిఐ ఖాతాకు తక్షణమే ఉపసంహరించుకోగలుగుతారు, నేరుగా మీ వాలెట్ నుండి FRND అప్లికేషన్ నుండి.'

    },
    tam: {
      heading: 'அடிக்கடி கேட்கப்படும் கேள்விகள்',
      q1: 'ஒரு நட்சத்திர பயிற்சியாளராக நான் எவ்வாறு பதிவு செய்வது?',
      a1: 'FRND-ன் நட்சத்திர பயற்சியாளராக மாற, மேலே தள்ளி எங்கள் இணையத்தில் பதிவு செய்யுங்கள். நீங்கள் அடிப்படை பதிவை முடித்தவுடன், FRND App-ஐ பதிவிறக்கம் செய்யுங்கள், அடுத்த கட்டத்திற்கு செல்ல எங்கள் குழுவினர் உங்களை 24 மணி நேரத்திற்குள் தொடர்பு கொள்வார்கள்.',
      q2: 'சமூகத்தில் தாக்கத்தை ஏற்படுத்த எனது பங்கு என்ன?',
      a2: 'ஒரு நட்சத்திர பயிற்சியாளராக, உலகெங்கிலும் உள்ள ஆயிரக்கணக்கான பயனர்களுடன் இணைக்கும் போது நீங்கள் எங்கள் குழுவினருடன் ஒருங்கிணைந்து செயல்படுவீர்கள், மேலும் அவர்களின் உறவுகள் தொடர்பான சிக்கலைத் தீர்க்க உதவுவீர்கள்.',
      q3: 'நான் எப்படி பணம் சம்பாதிக்க முடியும்?',
      a3: 'இந்தத் திட்டம் ஒரு கிக் பொருளாதார பாணியில் செயல்படும், அங்கு உங்கள் சமூகத்திற்கு உதவ நீங்கள் செலவழிக்கும் நேரத்திற்கு உங்களுக்கு பணம் வழங்கப்படும். FRND APP-ன் பணப்பையிலிருந்து உங்கள் யுபிஐ கணக்கின் வாயிலாக உடனடியாக உங்கள் வருவாயை எடுக்க முடியும்.'

    }
  },
  GAME_EXPERT_JOIN_US_FORM: {
    en: {
      heading: 'Join Us Now!',
      subheading: 'Please share your details and our team will contact you in the next 24 hours.',
      name: '*Name',
      number: '*Whatsapp Number',
      referredBy: '*Referred By',
      gender: 'Gender',
      male: 'Male',
      female: 'Female',
      other: 'Other',
      submitButton: 'Submit'
    },
    hi: {
      heading: 'हमसे अभी जुड़े !',
      subheading: 'कृपया अपनी डिटेल्स यहाँ शेयर करें और हमारी टीम अगले 24 घंटों में आपसे संपर्क करेगी।',
      name: '*नाम',
      number: '*व्हाट्सएप नंबर',
      referredBy: '*रेफ़र्ड बाय',
      gender: 'लिंग:',
      male: 'पुरुष',
      female: 'महिला',
      other: 'अन्य',
      submitButton: 'जमा करे'

    },
    tel: {
      heading: 'ఇప్పుడు మాతో చేరండి!',
      subheading: 'దయచేసి మీ వివరాలను పంచుకోండి మరియు రాబోయే 24 గంటల్లో మా బృందం మిమ్మల్ని సంప్రదిస్తుంది.',
      name: '*పేరు',
      number: '*వాట్సాప్ నంబర్',
      referredBy: '*సంప్రదించబడిన',
      gender: 'లింగం:',
      male: 'పురుషుడు',
      female: 'స్త్రీ',
      other: 'ఇతర',
      submitButton: 'సమర్పించండి'

    },
    tam: {
      heading: 'இப்போதே எங்களுடன் சேரவும்!',
      subheading: 'தயவுசெய்து, உங்கள் விபரங்களை பகிர்ந்து கொள்ளுங்கள், அடுத்த 24 மணி நேரத்தில் எங்கள் குழுவினர் உங்களைத் தொடர்பு கொள்வார்கள்.',
      name: '*பெயர்',
      number: '*வாட்ஸ்அப் எண்',
      referredBy: '*பரிந்துரைக்கப்பட்ட',
      gender: 'பாலினம்:',
      male: 'ஆண்',
      female: 'பெண்',
      other: 'மற்றவை',
      submitButton: 'சமர்ப்பி'

    }
  },
  CELEB_JOIN_US_FORM: {
    en: {
      heading: 'Join Us Now!',
      subheading: 'Please share your details and our team will contact you in the next 24 hours.',
      name: '*Name',
      number: '*Mobile Number',
      referredBy: '*How did you get to know about us?',
      subscribersCount: '*Follower/Subscriber Count',
      socialLink: '*Youtube / Instagram Link',
      gender: 'Gender',
      male: 'Male',
      female: 'Female',
      other: 'Other',
      submitButton: 'Submit'
    },
    hi: {
      heading: 'हमसे अभी जुड़े !',
      subheading: 'कृपया अपनी डिटेल्स यहाँ शेयर करें और हमारी टीम अगले 24 घंटों में आपसे संपर्क करेगी।',
      name: '*नाम',
      number: '*व्हाट्सएप नंबर',
      referredBy: '*रेफ़र्ड बाय',
      socialLink: '*Youtube / Instagram Link',
      subscribersCount: '*Follower/Subscriber Count',
      gender: 'लिंग:',
      male: 'पुरुष',
      female: 'महिला',
      other: 'अन्य',
      submitButton: 'जमा करे'

    },
    tel: {
      heading: 'ఇప్పుడు మాతో చేరండి!',
      subheading: 'దయచేసి మీ వివరాలను పంచుకోండి మరియు రాబోయే 24 గంటల్లో మా బృందం మిమ్మల్ని సంప్రదిస్తుంది.',
      name: '*పేరు',
      number: '*వాట్సాప్ నంబర్',
      referredBy: '*సంప్రదించబడిన',
      socialLink: '*Youtube / Instagram Link',
      subscribersCount: '*Follower/Subscriber Count',
      gender: 'లింగం:',
      male: 'పురుషుడు',
      female: 'స్త్రీ',
      other: 'ఇతర',
      submitButton: 'సమర్పించండి'

    },
    tam: {
      heading: 'இப்போதே எங்களுடன் சேரவும்!',
      subheading: 'தயவுசெய்து, உங்கள் விபரங்களை பகிர்ந்து கொள்ளுங்கள், அடுத்த 24 மணி நேரத்தில் எங்கள் குழுவினர் உங்களைத் தொடர்பு கொள்வார்கள்.',
      name: '*பெயர்',
      number: '*வாட்ஸ்அப் எண்',
      referredBy: '*பரிந்துரைக்கப்பட்ட',
      socialLink: '*Youtube / Instagram Link',
      subscribersCount: '*Follower/Subscriber Count',
      gender: 'பாலினம்:',
      male: 'ஆண்',
      female: 'பெண்',
      other: 'மற்றவை',
      submitButton: 'சமர்ப்பி'

    }
  },
  FOOTER: {
    en: {
    },
    hi: {
    },
    tel: {
    },
    tam: {
    }
  },

}